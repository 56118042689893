<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 card pt-2">
        <div class="col-6">
          <div class="card card-psh border">
            <div class="bg-light-primary rounded-top py-2 px-1">
              <div class="psh-header mb-0 d-flex align-items-center">
                <div class="mr-1 border-0">
                  <p-icon name="bi-gear-fill" size="48px" color="primary" />
                </div>
                <div class="d-flex flex-column">
                  <h4 class="card-title mb-25">
                    General Settings
                  </h4>
                  <p class="card-text mb-0">
                    Customize your warehouse settings
                  </p>
                </div>
              </div>
            </div>
            <div class="m-2">
              <div class="m-1 mb-1 d-flex justify-content-between">
                <div>
                  <h5 class="text-warning">
                    Enforce Payment Method
                  </h5>
                  <div class="text-primary mb-1">
                    Adding a payment method for clients will be required when enabling this option.
                  </div>
                </div>
                <div>
                  <dx-util-button :text="enforcePaymentMethod.value ? 'Disable' : 'Enable'" :type="enforcePaymentMethod.value ? 'danger' : 'success'" @click="togglePaymentMethod" />
                  <dx-util-check-box
                    v-if="false"
                    v-model="enforcePaymentMethod.value"
                    text="Enforce payment method"
                    class=" text-white"
                    @value-changed="updateEnforcePaymentMethod"
                  />
                </div>
              </div>
              <div class="divider mb-1">
                <div class="divider-text">
                </div>
              </div>
              <div class="m-1 mb-1 d-flex justify-content-between">
                <div>
                  <h5 class="text-warning">
                    Pull Amazon FBM Return Orders for Clients
                  </h5>
                  <div class="text-primary mb-1">
                    Amazon customer returns for sellers will be fetched as inbound items when this option is selected.
                  </div>
                </div>
                <div>
                  <dx-util-button :text="tenantStorePullAmzReturnOrder.value ? 'Disable' : 'Enable'"
                    :type="tenantStorePullAmzReturnOrder.value ? 'danger' : 'success'" @click="toggleTenantStorePullAmzReturnOrder"
                  />
                  <dx-util-check-box
                    v-if="false"
                    v-model="tenantStorePullAmzReturnOrder.value"
                    text="Pull Amazon FBM return orders for all seller stores"
                    class=" text-white"
                    @value-changed="updateTenantStorePullAmzReturnOrder"
                  />
                </div>
              </div>
              <div class="divider mb-1">
                <div class="divider-text">
                </div>
              </div>
              <div class="m-1 mb-3 d-flex justify-content-between">
                <div>
                  <h5 class="text-warning">
                    FBM Order Auto Close Delay (Hours)
                  </h5>
                  <div class="text-primary mb-1 pr-3">
                    Specify the time, in hours, for an order marked as 'Shipped' to automatically transition to the 'Shipped' category without
                    any charge or invoice. This duration should be between 0 and 96 hours.
                    <p class="text-warning">
                      If you prefer to manually close the FBM orders, please enter 0."
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <dx-util-number-box v-model="fbmAutoCloseDelay.value" :min="0" :max="96" :width="100" />
                  <dx-util-button text="Save" class="ml-1" type="success" width="60" @click="saveFBMAutoCloseDelay" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tenantService from '@/http/requests/tenant/tenan.settings'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import managedKeysEnum from '@/enums/managedKeysEnum'

export default {
  data() {
    return {
      enforcePaymentMethod: {
        id: null,
        key: managedKeysEnum.ENFORCE_PAYMENT_METHOD.key,
        value: false,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.BOOLEAN.key,
        version: null,
        referenceId: null,
      },
      tenantStorePullAmzReturnOrder: {
        id: null,
        key: managedKeysEnum.TENANT_STORE_PULL_AMZ_RETURN_ORDER.key,
        value: false,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.BOOLEAN.key,
        version: null,
        referenceId: null,
      },
      fbmAutoCloseDelay: {
        id: null,
        key: managedKeysEnum.FBM_ORDER_AUTO_CLOSE_DELAY_HOUR.key,
        value: null,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.INTEGER.key,
        version: null,
        referenceId: null,
      },
    }
  },
  async mounted() {
    await this.getEnforcePaymentMethod()
    await this.getTenantStorePullAmzReturnOrder()
    await this.getFbmAutoCloseDelay()
  },
  methods: {
    getEnforcePaymentMethod() {
      tenantService.fetchByKey(managedKeysEnum.ENFORCE_PAYMENT_METHOD.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.enforcePaymentMethod = {
            id: data.id,
            key: data.key,
            value: JSON.parse(data.value.toLowerCase()),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    getTenantStorePullAmzReturnOrder() {
      tenantService.fetchByKey(managedKeysEnum.TENANT_STORE_PULL_AMZ_RETURN_ORDER.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.tenantStorePullAmzReturnOrder = {
            id: data.id,
            key: data.key,
            value: JSON.parse(data.value.toLowerCase()),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    getFbmAutoCloseDelay() {
      tenantService.fetchByKey(managedKeysEnum.FBM_ORDER_AUTO_CLOSE_DELAY_HOUR.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.fbmAutoCloseDelay = {
            id: data.id,
            key: data.key,
            value: parseInt(data.value, 10),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    updateEnforcePaymentMethod() {
      const enforcePaymentMethod = { ...this.enforcePaymentMethod }
      if (enforcePaymentMethod.id) {
        tenantService.update(enforcePaymentMethod).then(result => {
          const { id, version, tenantId } = result.data.body
          this.enforcePaymentMethod = {
            ...this.enforcePaymentMethod,
            id,
            version,
            tenantId,
          }
        })
      } else {
        tenantService
          .create(enforcePaymentMethod)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.enforcePaymentMethod = {
              ...this.enforcePaymentMethod,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    updateTenantStorePullAmzReturnOrder() {
      const tenantStorePullAmzReturnOrder = { ...this.tenantStorePullAmzReturnOrder }
      if (tenantStorePullAmzReturnOrder.id) {
        tenantService.update(tenantStorePullAmzReturnOrder).then(result => {
          const { id, version, tenantId } = result.data.body
          this.tenantStorePullAmzReturnOrder = {
            ...this.tenantStorePullAmzReturnOrder,
            id,
            version,
            tenantId,
          }
        })
      } else {
        tenantService
          .create(tenantStorePullAmzReturnOrder)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.tenantStorePullAmzReturnOrder = {
              ...this.tenantStorePullAmzReturnOrder,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    saveFBMAutoCloseDelay() {
      const fbmAutoCloseDelay = { ...this.fbmAutoCloseDelay }
      if (fbmAutoCloseDelay.id) {
        tenantService.update(fbmAutoCloseDelay).then(result => {
          const { id, version, tenantId } = result.data.body
          this.fbmAutoCloseDelay = {
            ...this.fbmAutoCloseDelay,
            id,
            version,
            tenantId,
          }
        })
      } else {
        tenantService
          .create(fbmAutoCloseDelay)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.fbmAutoCloseDelay = {
              ...this.fbmAutoCloseDelay,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    togglePaymentMethod() {
      this.enforcePaymentMethod.value = !this.enforcePaymentMethod.value
      this.updateEnforcePaymentMethod()
    },
    toggleTenantStorePullAmzReturnOrder() {
      this.tenantStorePullAmzReturnOrder.value = !this.tenantStorePullAmzReturnOrder.value
      this.updateTenantStorePullAmzReturnOrder()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
